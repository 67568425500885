<template>
    <div>
   <div style="width: 80%;max-width: 900px;margin: auto;"><h1 class="title">Chaque rencontre commence ici : choisissez votre formule idéale ❤️‍🔥</h1></div>
   <div class="pricing-container">
        <!-- Standard Plan -->
        <div class="pricing-table">
            <h2>Starter</h2>
            <div>Testez l'expérience de rencontre sans engagement.</div>
            <div class="price">24,99 €<small style="font-size: 24px;"> / mois</small></div>
            <ul>
                <li> ❤️ Like illimités</li>
                <li> 💬 Tchat illimités</li>
                <li> ✉️ Notification mail</li>
                <li> 🔍 Recherche avancée</li>
                <li> 📱 Notification SMS</li>
                <li> 👀 Voir qui a vu mon profil</li>
                <li> 💌 Échange de coordonnées illimité</li>
                <li> ⏺️ Tchat vidéo</li>
            </ul>
            <button @click="goToStandardOffer($event,'price_1OUBejGpsPLgCJv0WupZe67r')" id="standard">Souscrire</button>
        </div>

        <!-- Premium Plan -->
        <div class="pricing-table premium">
            <div class="badge">Populaire</div>
            <h2>Zen</h2>
            <div>Offre idéale pour ceux cherchant une expérience prolongée.</div>
            <div class="price">59,99 €<small style="font-size: 24px;"> / 3 mois</small></div>
            <ul>
                <li> ❤️ Like illimités</li>
                <li> 💬 Tchat illimités</li>
                <li> ✉️ Notification mail</li>
                <li> 🔍 Recherche avancée</li>
                <li> 📱 Notification SMS</li>
                <li> 👀 Voir qui a vu mon profil</li>
                <li> 💌 Échange de coordonnées illimité</li>
                <li> ⏺️ Tchat vidéo</li>
            </ul>
            <button @click="goToStandardOffer($event,'price_1Pr0z9GpsPLgCJv0kIJDzOrz')" id="premium">Souscrire</button>
        </div>
    </div>
</div>
</template>
<script>
import { supabase } from '../lib/supabaseClient.js'
import { mapGetters } from 'vuex'
export default {
  name: 'Pricing',
  components: {
    
  },
  data() {
    return {
      
    }
  },
  computed: {
    ...mapGetters(['auth', 'profil', 'regions', 'constants']),
    isMe() {
      return this.auth.id == this.$route.params.id
    }
    },
  methods:{
    goToStandardOffer: async function(event,priceId){
        event.target.disabled = 'disabled'
        //document.getElementById("standard").disabled = 'disabled';
        const { data, error } = await supabase.functions.invoke('create-checkout-session', {
            body: {
              price_id: priceId, 
              email: this.auth.email,
              userId: this.auth.id,
              plan : priceId === 'price_1OUBejGpsPLgCJv0WupZe67r' ? 'STANDARD' : 'PREMIUM' 
            },
          })
          console.log(data);
        window.location.href= data.url;
    }
  }
}
</script>
  
<style scoped>

        .pricing-container {
            display: flex;
            justify-content: center; /* Center horizontally */
            align-items: center;     /* Center vertically */
            width: 80%;
            max-width: 900px;
            margin: auto;
        }

        .pricing-table {
            background-color: #fff;
            border: 1px solid #ddd;
            border-radius: 8px;
            padding: 20px;
            width: 45%;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            text-align: center;
            transition: transform 0.3s ease;
        }

        .pricing-table h2 {
            font-size: 24px;
            color: #333;
            font-weight: 700;
            margin-bottom: 20px;
        }

        .pricing-table .price {
            font-size: 36px;
            color: #007BFF;
            margin-bottom: 20px;
            font-weight: bold;
        }

        .pricing-table ul {
            list-style-type: none;
            padding: 0;
            margin: 0 0 20px 0;
        }

        .pricing-table ul li {
            margin: 10px 0;
            color: #555;
            margin: 10px 0;
            font-size: 20px;
            font-weight: 700;
        }
        button:disabled{
            opacity:0.65;
        }
        .pricing-table button {
            background-color: #007BFF;
            color: #fff;
            border: none;
            border-radius: 5px;
            padding: 15px 30px;
            font-size: 18px;
            cursor: pointer;
            margin-top: 20px;
            transition: background-color 0.3s ease;
        }

        .pricing-table button:hover {
            background-color: #0056b3;
        }

        /* Premium Highlight */
        .pricing-table.premium {
            /*color: #fff;*/
            transform: scale(1.05);
        }

        .pricing-table.premium .price {
            font-size: 48px;
        }

        .pricing-table.premium ul li {
            margin: 10px 0;
            font-size: 20px;
            font-weight: 700;
        }

        

        .badge {
            background-color: #FFD700;
            color: #333;
            padding: 5px 10px;
            font-size: 14px;
            border-radius: 20px;
            position: absolute;
            top: -10px;
            right: -10px;
            transform: rotate(-10deg);
        }

        /* Center the container and premium highlight */
        .pricing-container {
            display: flex;
            justify-content: center;
            gap: 20px;
        }

        .pricing-table.premium {
            order: 1; /* Moves the Premium plan to the center */
        }
        h1{
            margin: 50px 0px !important;
            font-size: 3rem !important;
            text-align: center !important;
            font-weight: 700;
        }
        .pricing-table ul {
            text-align: left;margin-left: 30px;
        }
    </style>