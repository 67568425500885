<template>
    <Pricing></Pricing>
</template>
  
<script>
import Pricing from "@/components/Pricing.vue"

export default {
  name: 'Upgrade',
  components: {
    Pricing
  },
  data() {
    return {
      
    }
  },
  
}
</script>
  