import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import Survey from '../views/Survey.vue'
import SurveySummarize from '../views/SurveySummarize.vue'
import UserDetail from '../views/UserDetail.vue'
import UserEdit from '../views/UserEdit.vue'
import UserMatch from '../views/UserMatch.vue'
import UserInvitation from '../views/UserInvitation.vue'
import Meeting from '../views/Meeting.vue'
import ChatRoom from '../views/Chat.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import RequestNewPassword from '../views/RequestNewPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Upgrade from '../views/Upgrade.vue'
import { supabase } from '../lib/supabaseClient.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      layout: 'AppLayoutDefault',
      requireAuth: true,
    },
  },
  {
    path: '/matches',
    name: 'Matches',
    component: UserMatch,
    meta: {
      layout: 'AppLayoutDefault',
      requireAuth: true,
    },
  },
  {
    path: '/chat-rooms',
    name: 'Chats',
    component: ChatRoom,
    meta: {
      layout: 'NoHeaderLayout',
      requireAuth: true,
    },
  },
  {
    path: '/chat-rooms/:id',
    name: 'Chats',
    component: ChatRoom,
    meta: {
      layout: 'NoHeaderLayout',
      requireAuth: false,
    },
  },
  {
    path: '/invitations',
    name: 'Invitations',
    component: UserInvitation,
    meta: {
      layout: 'AppLayoutDefault',
      requireAuth: true,
    },
  },
  {
    path: '/survey',
    name: 'Survey',
    component: Survey,
    meta: {
      layout: 'AppLayoutDefault',
      requireAuth: true,
    },
  },
  {
    path: '/meet',
    name: 'Meeting',
    component: Meeting,
    meta: {
      layout: 'AppLayoutDefault',
      requireAuth: true,
    },
  },
  {
    path: '/upgrade',
    name: 'Upgrade',
    component: Upgrade,
    meta: {
      layout: 'AppLayoutDefault',
      requireAuth: false,
    },
  },
  {
    path: '/survey/:id',
    name: 'SurveyResume',
    component: SurveySummarize,
    meta: {
      layout: 'AppLayoutDefault',
      requireAuth: true,
    },
  },
  {
    path: '/profil/:id',
    name: 'UserDetail',
    component: UserDetail,
    meta: {
      layout: 'AppLayoutDefault',
      requireAuth: true,
    },
  },
  {
    path: '/user/me',
    name: 'UserEdit',
    component: UserEdit,
    meta: {
      layout: 'AppLayoutDefault',
      requireAuth: true,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      layout: 'AppLayoutDefault',
      requireAuth: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'NoHeaderLayout',
      requireAuth: false,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      layout: 'NoHeaderLayout',
      requireAuth: false,
    },
  },
  {
    path: '/request-new-password',
    name: 'RequestNewPassword',
    component: RequestNewPassword,
    meta: {
      layout: 'NoHeaderLayout',
      requireAuth: false,
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      layout: 'NoHeaderLayout',
      requireAuth: false,
    },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// GOOD
router.beforeEach(async (to, from, next) => {
  var isAuthenticated = false
  var data = await getUser()

  var survey = await isSurveyCompleted(data.user)
  var hasUserPaid = await hasPaid(data.user)
  var meetTask = await isMeetingCompleted(data.user)
  isAuthenticated = checkUserAuthentication(data.user)

  if (to.name !== 'Login' && !isAuthenticated && to.meta.requireAuth === true) {
    next({ name: 'Login' })
  } else if (
    to.name !== 'Survey' &&
    to.name !== 'SurveyResume' &&
    survey != null &&
    survey.status == 0
  ) {
    next({ name: 'Survey' })
  }
  /*else if (
    to.name == 'Chats' && !hasUserPaid
  ) {
    next({ name: 'Upgrade' })
  } */
  /*else if (
    to.name !== 'Meeting' &&
    survey != null &&
    survey.status == 2 &&
    meetTask.status == 0
  ) {*/
    //next({ name: 'Meeting' })
  //} 
  else if (to.name == 'Home' && from.name !== 'Login') {
    next()
  } else {
    next()
  }
})

function checkUserAuthentication(user) {
  return user != null && user.id !== null
}

async function getUser() {
  try {
    const { data: user } = await supabase.auth.getUser()
    return user
  } catch (error) {
    console.error('Error checking authentication status:', error)
    return null
  }
}
async function hasPaid(user){
  return false;
}
async function isSurveyCompleted(user) {
  if (user != null) {
    let { data: task } = await supabase
      .from('task')
      .select(`id,duration,sort_order,status,action:user_action(*)`)
      .eq('action_id', 2)
      .eq('user_id', user.id)
      .limit(1)
      .single()
      .order('sort_order')
    return task
  } else return null
}
async function isMeetingCompleted(user) {
  if (user != null) {
    let { data: task } = await supabase
      .from('task')
      .select(`id,duration,sort_order,status,action:user_action(*)`)
      .eq('action_id', 3)
      .eq('user_id', user.id)
      .limit(1)
      .single()
      .order('sort_order')
    return task
  } else return null
}

export default router
