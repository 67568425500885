import Vue from 'vue'
import Vuex from 'vuex'
import { supabase } from '../lib/supabaseClient.js'
import router from '../router'
import constants from './constant.json'
import templates from '../emails/template.json'
import moment from 'moment'
import Handlebars from 'handlebars'

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    presenceChannel:null,
    onlineUsers:[],
    tasks: null,
    messages: null,
    channel: null,
    loadingMatch:false,
    survey_response: {
      id: null,
      survey_item: [],
      all_questions: [],
      survey: null,
      progress: 0,
      currentSurveyItemIndex: 0,
      status: 'IN_PROGRESS',
      itemHistory: [],
    },
    mime_type: { 'image/jpeg': 'jpg', 'image/png': 'png' },
    profil: {},
    attribute: null,
    user: null,
    industries: [],
    regions: [],
    constants: constants,
    survey_completed: false,
  }
}
export default new Vuex.Store({
  state: getDefaultState(),
  getters: {
    onlineUsers: (state) => state.onlineUsers,
    loadingMatch:(state) => state.loadingMatch,
    constants: (state) => state.constants,
    getSurveyItem: (state) => {
      return state.survey_response.survey_item
    },
    getCurrentItem: (state) => {
      if (state.survey_response != null)
        return state.survey_response.survey_item.filter(
          (t) => t.index == state.survey_response.currentSurveyItemIndex
        )[0]
      else return []
    },
    auth: (state) => state.user,
    progress: (state) => {
      return state.survey_response.progress
    },
    regions: (state) => {
      return state.regions
    },
    tasks: (state) => {
      return state.tasks
    },
    criterias: (state) => {
      return state.attribute
    },
    profil: (state) => {
      return state.profil
    },
    hasLoggedUser: (state) => {
      return state.user != null
    },
    currentSurveyItemIndex: (state) => {
      return state.survey_response.currentSurveyItemIndex
    },
    getCurrentUserResponse: (state) => {
      var item =
        state.survey_response.survey_item[
          state.survey_response.currentSurveyItemIndex
        ]
      return item.user_response
    },
    survey_completed: (state) => state.survey_completed,
    hasUserResponse: (state) => {
      if (state.survey_response.survey != null) {
        var item =
          state.survey_response.survey_item[
            state.survey_response.currentSurveyItemIndex
          ]
        return item.type == 'SECTION' ||
          (item.type == 'QUESTION' && item.user_response.length > 0)
          ? true
          : false
      } else return true
    },
  },
  mutations: {
    SET_PRESENCE_CHANNEL(state, channel) {
      state.presenceChannel = channel;
    },
    REMOVE_USER(state, userId) {
      state.onlineUsers = state.onlineUsers.filter((user) => user !== userId);
    },
    SET_ONLINE_USERS(state, users){
      state.onlineUsers = users;
    },
    SET_CHANNEL(state, payload) {
      state.channel = payload
    },
    ADD_MESSAGE(state, payload) {
      var msg = {
        _id: payload.id,
        content: payload.content,
        senderId: payload.sender_id,
        date: moment(payload.created_at).format('DD MMM'),
        timestamp: moment(payload.created_at).format('HH:mm'),
      }
      state.messages.push(msg)
    },
    CLEAR(state) {
      Object.assign(state, getDefaultState())
    },
    pushItem(state, payload) {
      state.survey_response.itemHistory.push(payload)
    },
    setcurrentSurveyItemIndex(state, index) {
      state.survey_response.currentSurveyItemIndex = index
      var nbItem = state.survey_response.survey_item.length
      state.survey_response.progress = Math.round((index * 100) / (nbItem - 1))
    },
    removeUserResponse(state, response_id) {
      var item =
        state.survey_response.survey_item[
          state.survey_response.currentSurveyItemIndex
        ]
      var index = item.user_response.findIndex((r) => {
        return r.response.id == response_id
      })

      item.user_response.splice(index, 1)
    },
    removeAllUserResponse(state) {
      var item =
        state.survey_response.survey_item[
          state.survey_response.currentSurveyItemIndex
        ]

      item.user_response.splice(0, item.user_response.length)
    },
    setCriterias(state, payload) {
      state.attribute = payload
    },
    setUserResponse(state, payload) {
      payload.user_id = state.user.id
      var item =
        state.survey_response.survey_item[
          state.survey_response.currentSurveyItemIndex
        ]
      if (item.type == 'QUESTION' && item.user_response.length == 0) {
        item.user_response.push(payload)
      } else {
        if (item.response_type == 'CHECK_BOXES') {
          item.user_response.push(payload)
        } else {
          item.user_response.length = 0
          item.user_response.push(payload)
        }
      }
    },
    setUser(state, payload) {
      state.user = payload
    },
    setUserProfile(state, payload) {
      state.user.profile = payload
    },
    setNextQuestion(state, payload) {
      state.survey_response.nextQuestion = payload
    },
    setIndustry(state, payload) {
      state.industries = payload
    },
    setRegion(state, payload) {
      state.regions = payload
    },
    setProfile(state, payload) {
      state.profil = payload
    },
    setTasks(state, payload) {
      state.tasks = payload
    },
    setSurvey(state, payload) {
      var i = 0
      state.survey_response.survey_item = []
      state.survey_response.id = payload.id
      payload.survey.section.map((s) => {
        if (s.questions.length > 0) {
          var an_item = {
            index: i++,
            title: s.title,
            description: s.desc,
            type: 'SECTION',
          }
          state.survey_response.survey_item.push(an_item)
          state.survey_response.all_questions.push(an_item)
          s.questions.map((q) => {
            var response = null
            if (q.id === 1) {
              response = {
                user_id: state.user.id,
                question_id: q.id,
                value: state.user.profile.first_name,
              }
            }
            if (q.id === 2) {
              response = {
                user_id: state.user.id,
                question_id: q.id,
                value: moment(state.user.profile.birth_date).format(
                  'DD/MM/YYYY'
                ),
              }
            }
            var an_item = {
              index: i++,
              gender: q.gender,
              title: q.title,
              attribute: q.attribute,
              section_id: s.id,
              info: q.info,
              choices:
                q.question_response && q.question_response.length > 0
                  ? q.question_response.map((qr) => qr.responses)
                  : [],
              type: 'QUESTION',
              question_id: q.id,
              response_type: q.response_type,
              user_response: response == null ? [] : [response],
            }
            state.survey_response.survey_item.push(an_item)
            state.survey_response.all_questions.push(an_item)
          })
        }
      })
      state.survey_response.survey = payload.survey
    },

    nextQuestion(state) {
      var gender_array = {
        1: 'H',
        2: 'W',
      }
      const nbItem = state.survey_response.survey_item.length
      var currentItem =
        state.survey_response.survey_item[
          state.survey_response.currentSurveyItemIndex
        ]
      if (currentItem.question_id == 3) {
        state.survey_response.survey_item = state.survey_response.all_questions
          .filter(
            (i) =>
              i.gender ==
                gender_array[currentItem.user_response[0].response.value] ||
              i.gender == 'BOTH' ||
              i.type == 'SECTION'
          )
          .map((element, index) => {
            return {
              ...element, // Spread the properties of the original object
              index: index, // Modify the 'name' property
            }
          })
      }
      if (
        state.survey_response.itemHistory[
          state.survey_response.currentSurveyItemIndex
        ] == null
      ) {
        state.survey_response.itemHistory.push(
          state.survey_response.currentSurveyItemIndex
        )
      }
      currentItem =
        state.survey_response.survey_item[
          state.survey_response.currentSurveyItemIndex
        ]
      if (
        currentItem.type == 'SECTION' ||
        (currentItem.type == 'QUESTION' && currentItem.response_type != 'RADIO')
      ) {
        state.survey_response.currentSurveyItemIndex += 1
      } else {
        if (currentItem.user_response[0].response.next_question_id != null) {
          var index = state.survey_response.survey_item.findIndex((i) => {
            return (
              i.question_id ==
              currentItem.user_response[0].response.next_question_id
            )
          })

          state.survey_response.currentSurveyItemIndex =
            index >= 0
              ? index
              : state.survey_response.currentSurveyItemIndex + 1
        } else {
          state.survey_response.currentSurveyItemIndex += 1
        }
      }

      state.survey_response.progress = Math.round(
        (state.survey_response.currentSurveyItemIndex * 100) / (nbItem - 1)
      )
      if (state.survey_response.progress == 100) {
        state.survey_completed = true
      }
    },
    previousQuestion(state) {
      var nbItem = state.survey_response.survey_item.length
      var currentItem = state.survey_response.itemHistory.pop()
      state.survey_response.currentSurveyItemIndex = currentItem
      state.survey_response.progress = Math.round(
        (state.survey_response.currentSurveyItemIndex * 100) / (nbItem - 1)
      )
    },
  },
  actions: {
    async trackUser({commit, state}){
      const presence_channel = supabase.channel('chat_tracking',{config: {
        presence: {
          key: state.user.id, // Unique identifier for the user
        },
      },})

      presence_channel
        .on('presence', { event: 'sync' }, () => {
          const newState = presence_channel.presenceState()
          const onlineUsers = Object.keys(newState);
          console.log('sync', onlineUsers)
          commit('SET_ONLINE_USERS', onlineUsers);

        })
        .on('presence', { event: 'join' }, ({ key, newPresences }) => {
          console.log('join', key, newPresences)
        })
        .on('presence', { event: 'leave' }, ({ key, leftPresences }) => {
          console.log('leave', key, leftPresences)
          commit('REMOVE_USER', key);
        })
        .subscribe()

        
        // Track the user's presence
        await presence_channel.track({
          userId: state.user.id,
          status: 'online',
          last_seen: new Date().toISOString(),
        });

        commit("SET_PRESENCE_CHANNEL",presence_channel);
      
    },
    async unsubscribePresenceChannel({ state }) {
      if (state.presenceChannel) {
        // Untrack first to clean up the presence state
        await state.presenceChannel.untrack();
       
        // Unsubscribe from the channel to free resources
       //  await state.presenceChannel.unsubscribe();

        console.log('User has been untracked and unsubscribed from presence.');
      }
    },
    async createPrivateChatRoom({ commit, state }) {
      let { data: room } = await supabase
        .from('chat_room')
        .insert([{ name: 'Admin (Private)' }])
        .select()

      // create chat_rooms users
      let { data: chat_room_user } = await supabase
        .from('chat_room_users')
        .insert([{ room_id: room.id, user_id: state.user.id }])
        .select()
    },
    async sendInteraction({ commit, state, dispatch }, obj) {
      try {
        const { data: interaction } = await supabase
          .from('user_interaction')
          .select(`*`)
          .eq('sender_id', state.user.id)
          .eq('receiver_id', obj.user.id)
          .select()
          console.log(interaction);
        if (interaction != null && interaction.length  > 0) {
          // update
          const { data: likeOrDislike } = await supabase
            .from('user_interaction')
            .update({ type: obj.type })
            .eq('id', interaction[0].id)
        } else {
          // create interaction
          if (obj.type === 'DISLIKE') {
            let { data: like } = await supabase
              .from('user_interaction')
              .insert([
                {
                  sender_id: state.user.id,
                  receiver_id: obj.user.id,
                  type: obj.type,
                  details: obj.declineForm.cause,
                  comments: obj.declineForm.details,
                },
              ])
              .select()
              .single()
          } else {
            let { data: like } = await supabase
              .from('user_interaction')
              .insert([
                {
                  sender_id: state.user.id,
                  receiver_id: obj.user.id,
                  type: obj.type,
                },
              ])
              .select()
              .single()

              // send simple like message
          let invitTemplate = templates.filter((t) => t.key == 'like')[0]
          let user1 = state.user.profile
          let user2 = obj.user
          const compile = Handlebars.compile(invitTemplate.html)
          let mail = {
            user1: user1.first_name,
            user2: user2.first_name,
            link: 'https://app.sakinamariage.com/profil/' + user1.id,
            u1: user1.first_name.substring(0, 1),
            u2: user2.first_name.substring(0, 1),
          }
          const { data, error } = await supabase.functions.invoke('resend', {
            body: {
              html: compile(mail),
              recipient: obj.user.email,
              subject: `${user1.first_name} a aimé votre profil`,
            },
          })
          console.log(`${obj.user.id}-${state.user.id}`);
          // if mutual interaction
        const { data: back_like } = await supabase
        .from('user_interaction')
        .select(`*`)
        .eq('sender_id', obj.user.id)
        .eq('receiver_id', state.user.id)
        .eq('type', 'LIKE')
        .select()
        
      if (back_like != null && back_like.length > 0) {
        
        // create chat_room
        let { data: room } = await supabase
          .from('chat_room')
          .insert([
            {
              name:
                obj.user.first_name + ' x ' + state.user.profile.first_name,
            },
          ])
          .select()
          .single()

        // create chat_room_users
        await supabase
          .from('chat_room_users')
          .insert([{ chat_room_id: room.id, user_id: obj.user.id }])

        // send chat invitatiion msg
        let invitTemplate = templates.filter((t) => t.key == 'invitation')[0]
        let user1 = state.user.profile
        let user2 = obj.user
        const compile = Handlebars.compile(invitTemplate.html)
        let mail = {
          user1: user1.first_name,
          user2: user2.first_name,
          link: 'https://app.sakinamariage.com/chat-rooms',
          u1: user1.first_name.substring(0, 1),
          u2: user2.first_name.substring(0, 1),
        }
        const { data, error } = await supabase.functions.invoke('resend', {
          body: {
            html: compile(mail),
            recipient: obj.user.email,
            subject: `${user1.first_name} vous invite à discuter`,
          },
        })
      }
          
          }
        }

      } catch (error) {
        console.log(error.error_description || error.message)
        throw error
      }
    },
    async sendDisLike({ commit, state, dispatch }, user) {
      try {
        const { data: pending_invitation } = await supabase
          .from('user_interaction')
          .select(`*`)
          .eq('receiver_id', user.id)
          .eq('type', 'DISLIKE')
        if (pending_invitation != null && pending_invitation.length > 0) {
          throw new Error('An invitation is pending for' + user.first_name)
        }

        // create interaction
        let { data: dislike } = await supabase
          .from('user_interaction')
          .insert([
            { sender_id: state.user.id, receiver_id: user.id, type: 'DISLIKE' },
          ])
          .select()
          .single()

        if (dislike != null && dislike.length > 0) {
          // closed chat_room if exists
          let { data: room } = await supabase
            .from('chat_room')
            .update({ status: 'CLOSED' })
            .eq('receiver_id', state.user.id)
            .eq('sender_id', user.id)
            .insert([
              { name: user.first_name + ' x ' + state.user.profile.first_name },
            ])
            .select()
            .single()

          // create chat_room_users
          await supabase
            .from('chat_room_users')
            .insert([{ chat_room_id: room.id, user_id: user.id }])

          // send invitation
          let invitTemplate = templates.filter(
            (t) => t.key == 'pending_invitation'
          )[0]
          let user1 = state.user.profile
          let user2 = user
          const compile = Handlebars.compile(invitTemplate.html)
          let obj = {
            user1: user1.first_name,
            user2: user2.first_name,
            link: 'https://app.sakinamariage.com/chat-rooms',
            u1: user1.first_name.substring(0, 1),
            u2: user2.first_name.substring(0, 1),
          }
          const { data, error } = await supabase.functions.invoke('resend', {
            body: {
              html: compile(obj),
              recipient: user.email,
              subject: `${user1.first_name} vous invite à discuter`,
            },
          })
        } else {
          // send simple like message
          let invitTemplate = templates.filter(
            (t) => t.key == 'pending_invitation'
          )[0]
          let user1 = state.user.profile
          let user2 = user
          const compile = Handlebars.compile(invitTemplate.html)
          let obj = {
            user1: user1.first_name,
            user2: user2.first_name,
            link: 'https://app.sakinamariage.com/chat-rooms',
            u1: user1.first_name.substring(0, 1),
            u2: user2.first_name.substring(0, 1),
          }
          const { data, error } = await supabase.functions.invoke('resend', {
            body: {
              html: compile(obj),
              recipient: user.email,
              subject: `${user1.first_name} vous invite à discuter`,
            },
          })
          console.log(data)
        }
      } catch (error) {
        console.log(error.error_description || error.message)
        throw error
      }
    },

    async inviteTuteur({ commit, state, dispatch }, tutor) {
      try {
        // create invitation
        const { data: user } = await supabase.functions.invoke('invite-user', {
          body: { username: tutor.username, email: tutor.email },
        })
        // create profil
        await supabase.from('profiles').insert([
          {
            first_name: tutor.username,
            id: user.id,
          },
        ])

        // create chat_rooms users
        let { data: chat_room_user } = await supabase
          .from('chat_room_users')
          .insert([{ chat_room_id: tutor.roomId, user_id: user.id }])
          .select()
      } catch (error) {
        console.log(error.error_description || error.message)
        throw error
      }
    },

    async acceptInvitation({ commit, state, dispatch }, user) {
      try {
        const { data: invitation } = await supabase
          .from('invitation')
          .update({ status: 'ACCEPTED' })
          .eq('receiver_id', state.user.id)
          .eq('sender_id', user.id)
          .eq('status', 'PENDING')
          .select()

        let invitTemplate = templates.filter(
          (t) => t.key == 'accept_invitation'
        )[0]
        let user1 = state.user.profile
        let user2 = user
        const compile = Handlebars.compile(invitTemplate.html)
        let obj = {
          user1: user1.first_name,
          user2: user2.first_name,
          link: 'https://app.sakinamariage.com/chat-rooms',
        }
        const { data, error } = await supabase.functions.invoke('resend', {
          body: {
            html: compile(obj),
            recipient: user2.email,
            subject: `${user1.first_name} a accepté votre invitation`,
          },
        })
        console.log(data)
      } catch (error) {
        console.log(error.error_description || error.message)
        throw error
      }
    },

    async rejectInvitation({ commit, state, dispatch }, form) {
      try {
        const { data: invitation } = await supabase
          .from('invitation')
          .update({ status: 'DECLINED' })
          .eq('receiver_id', state.user.id)
          .eq('sender_id', form.user.id)
          .eq('status', 'PENDING')
          .select()

        let invitTemplate = templates.filter(
          (t) => t.key == 'reject_invitation'
        )[0]
        let user1 = state.user.profile
        let user2 = form.user
        const compile = Handlebars.compile(invitTemplate.html)
        let obj = {
          user1: user1.first_name,
          user2: user2.first_name,
          link: 'https://app.sakinamariage.com/dashboard',
          u1: user1.first_name.substring(0, 1),
          u2: user2.first_name.substring(0, 1),
        }
        const { data, error } = await supabase.functions.invoke('resend', {
          body: {
            html: compile(obj),
            recipient: user2.email,
            subject: `${user1.first_name} a refusé votre invitation`,
          },
        })
        console.log(data)
      } catch (error) {
        console.log(error.error_description || error.message)
        throw error
      }
    },

    async loadUserInteraction({ commit, state }, userId) {
      const { data } = await supabase
        .from('user_interaction')
        .select(`*`)
        .or(
          `and(sender_id.eq.${state.user.id}, receiver_id.eq.${userId}),and(sender_id.eq.${userId}, receiver_id.eq.${state.user.id})`
        )
        .order('created_at', { ascending: false })
      return data;
    },

    async loadSurvey({ commit, state }, id) {
      try {
        let hasResponse = state.survey_completed
        //JSON.parse(localStorage.getItem("store")).survey_response.survey_item
        //.length > 0;
        if (!hasResponse) {
          let { data: survey_response } = await supabase
            .from('survey_response')
            .select(
              `id,survey(id,title,section(id,title,desc,questions:question(id,title,visible,gender,info,media_url,index,attribute!question_related_attribute_id_fkey(id,name,type),response_type,question_response(id,responses!question_response_response_id_fkey(id,title,value,index,icon,score,next_question_id,question_id)),responses!responses_question_id_fkey(id,title,value,index,icon,score,next_question_id,question_id))))`
            )
            .eq('survey_id', id)
            .eq('user_id', state.user.id)
            .eq('survey.section.questions.visible', true)
            .order('index', { foreignTable: 'survey.section' })
            .order('index', { foreignTable: 'survey.section.questions' })
            .order('index_order', {
              foreignTable: 'survey.section.questions.question_response',
            })
            .single()
          // mutate state
          commit('setSurvey', survey_response)
        } else {
          //let survey_response = localStorage.getItem('store').survey_response;
          // mutate state
          // commit('setSurvey',survey_response);
        }
      } catch (error) {
        alert(error.error_description || error.message)
        throw error
      }
    },
    async loadTasks({ commit }, id) {
      try {
        let { data: task } = await supabase
          .from('task')
          .select(`id,duration,user_id,sort_order,status,action:user_action(*)`)
          .eq('status', 0)
          .eq('user_id', id)
          .limit(1)
          .order('sort_order')

        // mutate state
        commit('setTasks', task)
        return task
      } catch (error) {
        alert(error.error_description || error.message)
        throw error
      }
    },
    async loadMatchs({ state,commit }, profil_id) {
      try {
        
        state.loadingMatch = true
        // load my matchs
        let { data: matchs } = await supabase
          .from('next_matchs')
          .select(
            `total_criterias,required_criterias,match_id(id,first_name,enabled,profil_attribute!profil_attribute_profil_id_fkey(id,value,min_value,max_value,attribute(id,name,group,label,type,icon)))`
          )
          .eq('profil_id', profil_id)
          .gte('required_criterias', 2)
          //.limit(1)
          .order('required_criterias', { ascending: false })
          .order('total_criterias', { ascending: false })

        if (matchs.length > 0) {
          let result = null
          for (let myMatch of matchs) {
            let { data: profils } = await supabase
              .from('matching')
              .select(
                `total_criterias,required_criterias,profil_id(id,first_name,enabled,profil_attribute!profil_attribute_profil_id_fkey(id,value,min_value,max_value,attribute(id,name,group,label,type,icon)))`
              )
              .eq('match_id', profil_id)
              .eq('profil_id', myMatch.match_id.id)
              .gte('required_criterias', 2)
              //.limit(1)
              .order('required_criterias', { ascending: false })
              .order('total_criterias', { ascending: false })
            // do my matches is in profil who match me
            if (profils != null && profils.length > 0) {
              console.log(
                `It'a match ${(profils[0].total_criterias / 21) * 100} %`
              )
              if (result == null) result = { p1: myMatch, p2: profils[0] }
              else if (profils[0].total_criterias > result.p2.total_criterias)
                result = { p1: myMatch, p2: profils[0] }
            }
          }
          state.loadingMatch = false
          if (result != null) {
            console.log(
              `It'a match ${(result.p1.total_criterias / 21) * 100} %`
            )
            return result
          } else return { p1: null, p2: null }
        } else  {
          state.loadingMatch = false
          return { p1: null, p2: null }
        }
        
      } catch (error) {
        state.loadingMatch = false
        alert(error.error_description || error.message)
        throw error
      }
    },
    async loadReceivedLikes({ commit }, profil_id) {
      try {
        // load my matchs
        let { data: invitations } = await supabase
          .from('user_interaction')
          .select(
            `*,sender_id(id,first_name,enabled,profil_attribute!profil_attribute_profil_id_fkey(id,value,min_value,max_value,attribute(id,name,group,label,type,icon)))`
          )
          .eq('receiver_id', profil_id)
          .eq('type', 'LIKE')

        return invitations
      } catch (error) {
        alert(error.error_description || error.message)
        throw error
      }
    },
    async sendMessage({ state }, message) {
      if (!message.content.trim()) return
      var msg = {
        content: message.content,
        sender_id: state.user.id,
        username: message.username,
        system: message.system,
        room_id: message.roomId,
        distributed: true,
      }
      return await supabase.from('chat_message').insert([msg]).select()
    },
    subscribeToMessage({ commit, state }) {
      var channel = supabase
        .channel('chat_message_channel')
        .on(
          'postgres_changes',
          { event: 'INSERT', schema: 'public', table: 'chat_message' },
          (payload) => {
            console.log('Change received!', payload)
            commit('ADD_MESSAGE', payload.new)
          }
        )
        .subscribe()
      commit('SET_CHANNEL', channel)
    },
    unsuscribeToMessage({ state }) {
      supabase.removeChannel(state.channel)
    },
    async loadSentLikes({ commit }, profil_id) {
      try {
        // load my matchs
        let { data: invitations } = await supabase
          .from('user_interaction')
          .select(
            `*,receiver_id(id,first_name,enabled,profil_attribute!profil_attribute_profil_id_fkey(id,value,min_value,max_value,attribute(id,name,group,label,type,icon)))`
          )
          .eq('sender_id', profil_id)
          .eq('type', 'LIKE')
        return invitations
      } catch (error) {
        alert(error.error_description || error.message)
        throw error
      }
    },
    async loadDislikes({ commit }, profil_id) {
      try {
        // load my matchs
        let { data: invitations } = await supabase
          .from('user_interaction')
          .select(
            `*,receiver_id(id,first_name,enabled,profil_attribute!profil_attribute_profil_id_fkey(id,value,min_value,max_value,attribute(id,name,group,label,type,icon)))`
          )
          .eq('sender_id', profil_id)
          .eq('type', 'DISLIKE')
        return invitations
      } catch (error) {
        alert(error.error_description || error.message)
        throw error
      }
    },
    async signUpAction({ commit, dispatch }, form) {
      try {
        let { data, error } = await supabase.auth.signUp({
          email: form.email,
          password: form.password,
          options: {
            emailRedirectTo: process.env.VUE_APP_EMAIL_REDIRECT_URL,
            data: {
              firstName: form.firstName,
              phoneNumber: form.phoneNumber,
              birthDate: form.birthDate,
              role: 2,
            },
          },
        })
        await supabase.from('profiles').insert([
          {
            first_name: form.firstName,
            id: data.user.id,
            birth_date: form.birthDate,
            phone_number: form.phoneNumber,
          },
        ])

        if (error) throw error
        // alert("You've been registered successfully");
        //await dispatch("signInAction", form)
        commit('setUser', data.user)
        router.push('/dashboard')

        //

        let { data: profile } = await supabase
          .from('profiles')
          .select(`*`)
          .eq('id', data.user.id)
          .single()

        commit('setProfile', profile)
      } catch (error) {
        alert(error.error_description || error.message)
        throw error
      }
    },
    async uploadAvatar({ state, commit }, avatar) {
      let me = state.profil.id

      let path = `${me}.${state.mime_type[avatar.type]}`
      const { data, error } = await supabase.storage
        .from('avatars')
        .upload(path, avatar, {
          cacheControl: '3600',
          contentType: avatar.type,
          upsert: true,
        })

      const p = await supabase
        .from('profiles')
        .update({ avatar: data.path })
        .eq('id', state.user.id)
        .select()
      commit('setProfile', p.data[0])
    },
    async getAvatar({ state }, path) {
      const { data, error } = await supabase.storage
        .from('avatars')
        .download(path+'?v='+ Date.now())

      return data
    },
    async signInAction({ commit }, form) {
      await supabase.auth
        .signInWithPassword({
          email: form.email,
          password: form.password,
        })
        .then(async ({ data, error }) => {
          if (error) throw error

          commit('setUser', data.user)
          await supabase
            .from('profiles')
            .select(`*`)
            .eq('id', data.user.id)
            .single()
            .then(({ data: profile }) => {
              commit('setUserProfile', profile)
            })
          // router.push("/dashboard");
        })
        .catch((error) => {
          throw error
        })
    },
    async signOutAction({ commit }) {
      try {
        await supabase.auth.signOut()
        commit('CLEAR')
        localStorage.clear()
        //alert("You've been logged Out successfully");
        await router.push('/login')
      } catch (error) {
        alert(error.error_description || error.message)
      }
    },
    async loadUser({ dispatch, commit }) {
      try {
        const {
          data: { user },
        } = await supabase.auth.getUser()
        user.profile = {}
        commit('setUser', user)
        const profile = await supabase
          .from('profiles')
          .select(
            `*,profil_attribute!profil_attribute_profil_id_fkey(id,value,min_value,max_value,attribute(id,name,group,label,type,icon)),partner_attribute!partner_attribute_profil_id_fkey(id,value,min_value,max_value,attribute(id,name,group,label,type,icon))`
          )
          .eq('id', user.id)
          .single()

        commit('setUserProfile', profile.data)
      } catch (error) {
        if (error.status === 401) {
          console.error('Unauthorized')
          // Redirigez l'utilisateur vers la page de connexion
          // window.location.href = '/login';
          //router.push("/login");
        } else {
          console.error(error.message)
        }
      }
    },
    async requestPassword({ commit }, form) {
      let email = form.email

      await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: 'https://app.sakinamariage.com/reset-password',
      })
      commit('CLEAR')
    },
    async updatePassword({ commit }, form) {
      const { error } = await supabase.auth.updateUser({
        password: form.passwordConfirm,
      })
      if (error) throw error
    },
    async loadMyProfil({ commit, dispatch }) {
      const {
        data: { user },
      } = await supabase.auth.getUser()
      await supabase
        .from('profiles')
        .select(`*`)
        .eq('id', user.id)
        .single()
        .then(({ data: profile }) => {
          commit('setUserProfile', profile)
        })
    },
    async loadProfil({ commit }, id) {
      let { data } = await supabase
        .from('profiles')
        .select(
          `*,profil_attribute!profil_attribute_profil_id_fkey(id,value,min_value,max_value,attribute(id,name,group,label,type,icon)),partner_attribute!partner_attribute_profil_id_fkey(id,value,min_value,max_value,attribute(id,name,group,label,type,icon))`
        )
        .eq('id', id)
        .single()
      commit('setProfile', data)
      return data
    },
    async loadUserDetails({ commit }, id) {
      let data = await supabase
        .from('profiles')
        .select(
          `*,profil_attribute!profil_attribute_profil_id_fkey(id,value,min_value,max_value,attribute(id,name,group,label,type,icon)),partner_attribute!partner_attribute_profil_id_fkey(id,value,min_value,max_value,attribute(id,name,group,label,type,icon))`
        )
        .eq('id', id)
        .single()

      return data.data
    },
    async updateProfile({ commit, state }, datas) {
      // delete all previous attribute

      const { error1 } = await supabase
        .from('profil_attribute')
        .delete()
        .eq('profil_id', state.profil.id)
      // reinsert new ones

      let new_attribute = []

      let keys = Object.keys(datas)
      for (let groupKey of keys) {
        let attribute = state.attribute.filter((c) => {
          return c.name.toLowerCase() == groupKey
        })[0]
        if (attribute.type === 'switch') {
          let value = datas[groupKey]
          new_attribute.push({
            profil_id: state.profil.id,
            attribute_id: attribute.id,
            value: value ? 272 : 273,
          })
        } else if (attribute.type === 'list') {
          let value = datas[groupKey]
          new_attribute.push({
            profil_id: state.profil.id,
            attribute_id: attribute.id,
            value: value.value,
          })
        } else if (attribute.type === 'range') {
          let value = datas[groupKey]
          new_attribute.push({
            profil_id: state.profil.id,
            attribute_id: attribute.id,
            min_value: value[0],
            max_value: value[1],
            value: value[0],
          })
        } else if (attribute.type === 'multilist') {
          let value = datas[groupKey]
          for (let v in value) {
            new_attribute.push({
              profil_id: state.profil.id,
              attribute_id: attribute.id,
              value: value[v].value,
            })
          }
        }
      }

      const { data, error2 } = await supabase
        .from('profil_attribute')
        .insert(new_attribute)
        .select()
    },

    async updatePartnerCriteria({ commit, state }, datas) {
      // delete all previous attribute
      const { error1 } = await supabase
        .from('partner_attribute')
        .delete()
        .eq('profil_id', state.profil.id)
      // reinsert new ones

      let new_attribute = []

      let keys = Object.keys(datas)
      for (let groupKey of keys) {
        let attribute = state.attribute.filter((c) => {
          return c.name.toLowerCase() == groupKey
        })[0]
        if (attribute.type === 'switch') {
          let value = datas[groupKey]
          new_attribute.push({
            profil_id: state.profil.id,
            attribute_id: attribute.id,
            value: value ? 272 : 273,
          })
        } else if (attribute.type === 'range') {
          let value = datas[groupKey]
          new_attribute.push({
            profil_id: state.profil.id,
            attribute_id: attribute.id,
            min_value: value[0],
            max_value: value[1],
          })
        } else {
          let value = datas[groupKey]
          for (let v in value) {
            new_attribute.push({
              profil_id: state.profil.id,
              attribute_id: attribute.id,
              value: value[v].value,
            })
          }
        }
      }

      const { data, error2 } = await supabase
        .from('partner_attribute')
        .insert(new_attribute)
        .select()
    },
    async loadCriterias({ commit }) {
      await supabase
        .from('attribute')
        .select(`*`)
        .then(({ data: attribute }) => {
          commit('setCriterias', attribute)
        })
        .catch((error) => {
          throw error
        })
    },
    async loadRooms({ commit }) {
      var rooms = await supabase
        .from('chat_room')
        .select(
          `*,chat_room_users(profiles(id,first_name,phone_number)),user_interaction(id,sender_id(id,first_name),receiver_id(id,first_name))`
        )
        .order('created_at')
      return rooms.data
    },
    async terminateChatRoom({ commit }, chatRoomId) {
      var rooms = await supabase
        .from('chat_room')
        .update({ status: 'CLOSE' })
        .eq('id', chatRoomId)
        .select()
      return rooms.data
    },
    async loadOneRoom({ commit }, id) {
      var { data: room } = await supabase
        .from('chat_room')
        .select(
          `*,chat_room_users(profiles(id,first_name,phone_number)),user_interaction(id,sender_id(id,first_name),receiver_id(id,first_name))`
        )
        .eq('id', id)
        .single()
      return room
    },
    async updateRoomLastMessage({ commit }, chat_room) {
      const { data: room } = await supabase
        .from('chat_room')
        .update({ last_message: chat_room.last_message })
        .eq('id', chat_room.id)
        .select()
    },
    async loadMessages({ commit, state }, room_id) {
      var messages = await supabase
        .from('chat_message')
        .select(`*`)
        .eq('room_id', room_id)
        .order('created_at', { ascending: true })
      return messages.data
    },

    async loadIndustry({ commit }) {
      await supabase
        .from('industry')
        .select(`id,name`)
        .then(({ data: industry }) => {
          commit('setIndustry', industry)
        })
        .catch((error) => {
          throw error
        })
    },
    async loadRegion({ commit }) {
      await supabase
        .from('region')
        .select(`id,name,value`)
        .order('name')
        .then(({ data: region }) => {
          commit('setRegion', region)
        })
        .catch((error) => {
          throw error
        })
    },
    async sendUserResponse({ state, dispatch }) {
      var profil_attribute = []
      var chat_gpt_array = []
      var user_response = []
      var partner_attribute = []
      var profile = {}
      var size = null
      var gender = null
      // MY PROFILE
      //chat_gpt_array.push({attribute:'NAME',value: state.user.profil.firstName})
      state.survey_response.survey_item
        .filter(
          (i) =>
            i.attribute != null &&
            i.user_response.length > 0 &&
            (i.section_id == 1 || i.section_id == 2)
        )
        .map((si) => {
          var response = null
          var pa = null
          console.log(si.attribute.name)
          if (si.attribute.id == 9) {
            // location / region
            response = si.user_response[0]
            //profile[si.attribute.name.toLowerCase()] = response.value;
            pa = {
              profil_id: state.user.id,
              attribute_id: si.attribute.id,
              value: response.value,
            }
            profil_attribute.push(pa)
            //chat_gpt_array.push({attribute:si.attribute.name,value: state.constants[si.attribute.name.toLowerCase()].filter(p=>p.value==response.value)[0].label})
          } else if (si.attribute != null && si.attribute.type == 'switch') {
            response = si.user_response[0].response
            //profile[si.attribute.name.toLowerCase()] = response.value;
            pa = {
              profil_id: state.user.id,
              attribute_id: si.attribute.id,
              value: response.id,
            }
            profil_attribute.push(pa)
            //chat_gpt_array.push({attribute:si.attribute.name,value: response.id == 272? 'Oui' : 'Non'})
          } else if (si.attribute != null && si.attribute.type == 'list') {
            response = si.user_response[0].response
            if (si.attribute.id == 31) {
              gender = response.id
            }
            //profile[si.attribute.name.toLowerCase()] = response.value;
            pa = {
              profil_id: state.user.id,
              attribute_id: si.attribute.id,
              value: response.id,
            }
            profil_attribute.push(pa)
            //chat_gpt_array.push({attribute:si.attribute.name,value: state.constants[si.attribute.name.toLowerCase()].filter(p=>p.value==response.id)[0].label})
          } else if (si.attribute != null && si.attribute.type == 'numeric') {
            response = si.user_response[0]
            profile[si.attribute.name.toLowerCase()] = response.value
            pa = {
              profil_id: state.user.id,
              attribute_id: si.attribute.id,
              value: response.value,
            }
            profil_attribute.push(pa)
            //chat_gpt_array.push({attribute:si.attribute.name,value: response.value})
          } else if (si.attribute != null && si.attribute.type == 'multilist') {
            response = si.user_response.map((r) => {
              return {
                profil_id: state.user.id,
                attribute_id: si.attribute.id,
                value: r.response.id,
              }
            })
            /*.join(",");
            // profile[si.attribute.name.toLowerCase()] = response;
            /* pa = {
              profil_id: state.user.id,
              attribute_id: si.attribute.id,
              value: response,
            };*/
            profil_attribute.push(...response)
          } else if (si.attribute != null && si.attribute.type == 'range') {
            response = si.user_response[0]
            if (si.attribute.id == 24) {
              size = response.value
              //chat_gpt_array.push({attribute:si.attribute.name,value: size})
            }
            if (si.attribute.id == 10) {
              const getAge = (birthDate) =>
                Math.floor(
                  (new Date() -
                    moment(birthDate, 'DD/MM/YYYY').toDate().getTime()) /
                    3.15576e10
                )
              var age = getAge(response.value)
              pa = {
                profil_id: state.user.id,
                attribute_id: si.attribute.id,
                min_value: age,
                max_value: age,
                value: age,
              }
              //chat_gpt_array.push({attribute:si.attribute.name,value: age})
            } else
              pa = {
                profil_id: state.user.id,
                attribute_id: si.attribute.id,
                min_value: response.min_value || response.value,
                max_value: response.max_value || response.value,
                value: response.value,
              }
            profil_attribute.push(pa)
          }

          //console.log(chat_gpt_array)
          //profil_attribute.push(pa);
        })

      // MY MATCH
      state.survey_response.survey_item
        .filter(
          (i) =>
            i.attribute != null &&
            i.user_response.length > 0 &&
            i.section_id == 3
        )
        .map((si) => {
          var response = null
          var pa = null
          if (si.attribute.id == 9) {
            // location / region
            response = si.user_response[0]
            //profile[si.attribute.name.toLowerCase()] = response.value;
            pa = {
              profil_id: state.user.id,
              attribute_id: si.attribute.id,
              value: response.value,
            }
            partner_attribute.push(pa)
          } else if (si.attribute != null && si.attribute.type == 'switch') {
            response = si.user_response[0].response
            //profile[si.attribute.name.toLowerCase()] = response.value;
            pa = {
              profil_id: state.user.id,
              attribute_id: si.attribute.id,
              value: response.id,
            }
            partner_attribute.push(pa)
          } else if (si.attribute != null && si.attribute.type == 'numeric') {
            response = si.user_response[0]
            pa = {
              profil_id: state.user.id,
              attribute_id: si.attribute.id,
              value: response.value,
            }
            partner_attribute.push(pa)
          } else if (
            si.attribute != null &&
            (si.attribute.type == 'multilist' || si.attribute.type == 'list')
          ) {
            response = si.user_response.map((r) => {
              return {
                profil_id: state.user.id,
                attribute_id: si.attribute.id,
                value: r.response.id,
              }
            })

            partner_attribute.push(...response)
          } else if (si.attribute != null && si.attribute.type == 'range') {
            response = si.user_response[0]

            if (si.attribute.id == 24) {
              // Est plus grand que moi

              if (response.response.id == 272) {
                // si oui
                pa = {
                  profil_id: state.user.id,
                  attribute_id: si.attribute.id,
                  min_value: size,
                  max_value: 200,
                }
              } else {
                pa = {
                  profil_id: state.user.id,
                  attribute_id: si.attribute.id,
                  min_value: 0,
                  max_value: size,
                }
              }
            } else {
              pa = {
                profil_id: state.user.id,
                attribute_id: si.attribute.id,
                min_value: response.min_value,
                max_value: response.max_value,
              }
            }
            partner_attribute.push(pa)
          }
        })

      let sex = {
        profil_id: state.user.id,
        attribute_id: 31,
        value: gender == 1 ? 2 : 1,
      }
      partner_attribute.push(sex)

      // SURVEY
      /* state.survey_response.survey_item
        .filter((si) => si.type == "QUESTION" && si.user_response.length > 0)
        .map((si) => {
          if (
            si.response_type == "SHORT_ANSWER" ||
            si.response_type == "TEXT" ||
            si.response_type == "DATE" ||
            si.response_type === "DROPDOWN"
          ) {
            var response = si.user_response[0];
            var pa = {
              profil_id: state.user.id,
              question_id: response.question_id,
              value: response.value,
              survey_response_id: state.survey_response.id,
            };
            user_response.push(pa);
          } else if (si.response_type == "RANGE") {
            response = si.user_response[0];
            pa = {
              profil_id: state.user.id,
              question_id: response.question_id,
              min_value: response.min_value,
              max_value: response.max_value,
              survey_response_id: state.survey_response.id,
            };
            user_response.push(pa);
          } else {
            si.user_response.forEach((r) => {
              pa = {
                profil_id: state.user.id,
                question_id: r.question_id,
                response_id: r.response.id,
                survey_response_id: state.survey_response.id,
              };
              user_response.push(pa);
            });
          }
        });
      console.log(profil_attribute);
      console.log(partner_attribute);
      console.log(user_response);

      var findSurveyItem = (question_id) => {
        return (
          state.survey_response.survey_item.filter(
            (si) =>
              (si.type =
                "QUESTION" &&
                si.user_response &&
                si.user_response.length > 0 &&
                si.question_id == question_id)
          )[0] || null
        );
      };
      const getAge = (birthDate) =>
        Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);

      var first_name = findSurveyItem(1)
        ? findSurveyItem(1).user_response[0].value
        : null;
      var birth_date = findSurveyItem(2).user_response[0].value;
      var age = findSurveyItem(2)
        ? getAge(findSurveyItem(2).user_response[0].value)
        : null; //10
      var nb_childs = findSurveyItem(6)
        ? findSurveyItem(6).user_response[0].value
        : null;
      var want_more_childs = findSurveyItem(14)
        ? findSurveyItem(14).user_response[0].response.value === "true"
        : null;

      var si = findSurveyItem(7);
      var diploma = si
        ? si.choices.filter((c) => c.id == si.user_response[0].response.id)[0]
            .title
        : "";

      var region = "ILE DE FRANCE"; // 9
      var other_region = true;
      var prayer = true; //6
      var eat_halal = false; //1
      var is_smoking = findSurveyItem(10)
        ? findSurveyItem(10).user_response[0].response.value === "true"
        : null; //2
      var is_drinking = findSurveyItem(12)
        ? findSurveyItem(12).user_response[0].response.value === "true"
        : null; //2 ;// 3
      si = findSurveyItem(15);
      var hobbies = si
        ? si.user_response
            .map((res) => {
              return si.choices.filter((c) => c.id == res.response.id)[0].title;
            })
            .join(",")
        : ""; //
      var sport = findSurveyItem(11)
        ? findSurveyItem(11).user_response[0].response.value === "true"
        : null; //2

      si = findSurveyItem(4);
      var marital_status = si
        ? si.choices.filter((c) => c.id == si.user_response[0].response.id)[0]
            .title
        : null; //

      si = findSurveyItem(8);
      var professional_status = si
        ? si.choices.filter((c) => c.id == si.user_response[0].response.id)[0]
            .title
        : null;
      var industry = null;
      var height = findSurveyItem(17)
        ? findSurveyItem(17).user_response[0].value
        : ""; // 24

      si = findSurveyItem(19);
      var shape = si
        ? si.choices.filter((c) => c.id == si.user_response[0].response.id)[0]
            .title
        : null; // //23

      si = findSurveyItem(18);
      var origin = si
        ? si.choices.filter((c) => c.id == si.user_response[0].response.id)[0]
            .title
        : null; // //8
      si = findSurveyItem(3);
      var partner_gender = si
        ? si.choices.filter((c) => c.id != si.user_response[0].response.id)[0]
            .title
        : null; // 25
      var gender = si
        ? si.choices.filter((c) => c.id == si.user_response[0].response.id)[0]
            .title
        : null; // 25
      si = findSurveyItem(108);
      var dress_style = si
        ? si.user_response
            .map((res) => {
              return si.choices.filter((c) => c.id == res.response.id)[0].title;
            })
            .join(",")
        : ""; //21

      var hijab = ""; //21

      var partner_heigth_min = findSurveyItem(26).user_response[0].min_value;
      var partner_heigth_max = findSurveyItem(26).user_response[0].max_value;
      si = findSurveyItem(96);
      var partner_origin = si
        ? si.choices.filter((c) => c.id == si.user_response[0].response.id)[0]
            .title
        : null; // //8
      si = findSurveyItem(27);
      var partner_shape = si
        ? si.choices.filter((c) => c.id == si.user_response[0].response.id)[0]
            .title
        : null;

      si = findSurveyItem(111);
      var partner_dress_style = si
        ? si.user_response
            .map((res) => {
              return si.choices.filter((c) => c.id == res.response.id)[0].title;
            })
            .join(",")
        : "";

      si = findSurveyItem(104);
      var partner_professional_status = si
        ? si.choices.filter((c) => c.id == si.user_response[0].response.id)[0]
            .title
        : null;

      si = findSurveyItem(110);
      var partner_sport = si
        ? si.choices.filter((c) => c.id == si.user_response[0].response.id)[0]
            .title
        : null;

      var partner_age_min = findSurveyItem(95).user_response[0].min_value;
      var partner_age_max = findSurveyItem(95).user_response[0].max_value;

      var partner_region = "";

      si = findSurveyItem(24);
      var partner_already_married = si
        ? si.choices.filter((c) => c.id == si.user_response[0].response.id)[0]
            .title
        : null;

      si = findSurveyItem(25);
      var partner_has_kids = si
        ? si.choices.filter((c) => c.id == si.user_response[0].response.id)[0]
            .title
        : null;

      si = findSurveyItem(105);
      var partner_want_kids = si
        ? si.choices.filter((c) => c.id == si.user_response[0].response.id)[0]
            .title
        : null;

      si = findSurveyItem(28);
      var partner_diploma = "";

      si = findSurveyItem(103);
      var partner_prayer = si
        ? si.choices.filter((c) => c.id == si.user_response[0].response.id)[0]
            .title
        : null;

      si = findSurveyItem(102);
      var partner_halal = si
        ? si.choices.filter((c) => c.id == si.user_response[0].response.id)[0]
            .title
        : null;

      si = findSurveyItem(99);
      var partner_smoking = si
        ? si.choices.filter((c) => c.id == si.user_response[0].response.id)[0]
            .title
        : null;

      si = findSurveyItem(100);
      var partner_drinking = si
        ? si.choices.filter((c) => c.id == si.user_response[0].response.id)[0]
            .title
        : null;

      var bio = `Salam alaykoum je m’appelle ${first_name}! j’ai ${age} ans ${
        marital_status
          ? `je suis actuellement ${marital_status.toLowerCase()},`
          : ""
      } ${nb_childs > 0 ? `avec ${nb_childs} enfant(s)` : "sans enfant."} ${
        want_more_childs > 0 ? ",mais je souhaite en avoir d'autres." : ""
      } J’ai un diplôme niveau ${diploma} et je suis actuellement ${professional_status.toLowerCase()} ${
        industry ? `dans le secteur suivant : ${industry},` : ""
      } ${region ? `j’habite en région ${region}` : ""} ${
        other_region ? "mais je suis ouvert aux profils d’autres régions" : ""
      } ${prayer ? "je fais mes 5 prières quotidiennes," : "je ne prie pas,"} ${
        is_smoking ? "je fume," : "je ne fume pas,"
      } ${is_drinking ? "je bois," : "je ne bois pas,"} ${
        eat_halal ? "et je mange halal." : "je ne mange pas halal."
      } J’aime les activités suivantes: ${hobbies.toLowerCase()}`;
      var physical_desc = `<ul><li>Origine : ${origin} </li> <li>Taille : ${height} cm </li> <li>Silhouette: ${shape}. </li> <li> Activité physique : ${
        sport ? `Oui` : "Non"
      }</li> <li> Style vestimentaire :  ${dress_style} </li> <li> Porte le hijab: ${
        hijab ? "Oui" : "Non"
      }</li>`;
      //var partner_desc = `Je recherche ${partner_gender} entre ${partner_heigth_min} et ${partner_heigth_max} ans d'origine: ${partner_origin} qui mesure plus {} de moins de. pratique une activité sportive. Je recherche une personne qui : <ul><li>effectue les 5 prieres</li><li>mange halal</li><li>ne fume pas</li><li>ne bois pas</li> <li>et porte le hijab</li></ul>,<br/> je recherche une femme jamais mariée et sans enfants mais qui souhaite en avoir je recherche uune personne habitant la meme region que la mienne. Je recherche un femme avec un niveau d'etude minium : et qui est actuellement`  ;
      var partner_desc = `<ul><li>Je recherche : ${partner_gender}</li> <li>Son age: entre ${partner_age_min} et ${partner_age_max} ans </li><li>Sa taille: entre ${partner_heigth_min} et ${partner_heigth_max} cm </li><li>Sa silhouette: ${partner_shape} </li><li> Son origine: ${partner_origin}</li> <li>Habite en region : ${partner_region}</li><li>Niveau d'étude: ${partner_diploma}</li><li>Situation professionelle: ${partner_professional_status}</li><li>Style vestimentaire: ${partner_dress_style}</li><li>Pratique une activité sportive: ${partner_sport} </li><li>Prie: ${partner_prayer}</li><li>Mange halal : ${partner_halal}</li><li>Fume: ${partner_smoking}</li><li>Consomme de l'alcool: ${partner_drinking}</li> <li>Porte le hijab : ${hijab}</li><li>Jamais marié(e): ${partner_already_married} </li><li>A des enfants: ${partner_has_kids}</li><li>Veut des enfants: ${partner_want_kids}</li></ul>`;

      var profil = {
        first_name,
        birth_date,
        height,
        bio,
        physical_desc,
        partner_desc,
        gender,
        region,
      };
      console.log(profil);
*/

      /* let { data, error } = await supabase.rpc("create_account", {
        profil_attribute,
        partner_attribute,
      });*/

      // HERE 1

      let { error } = await supabase
        .from('profil_attribute')
        .insert(profil_attribute)
      if (error) throw error

      ;({ error } = await supabase
        .from('partner_attribute')
        .insert(partner_attribute))
      if (error) throw error

      await supabase
        .from('survey_response')
        .update({ status: 2 })
        .eq('survey_id', 'cd06ef1c-1f42-4a58-b553-d76812858ce5')
        .eq('user_id', state.user.id)
        .select()

      await supabase
        .from('task')
        .update({ status: 2 })
        .eq('action_id', 2)
        .eq('user_id', state.user.id)
        .select()
    },
    async editResponse({ commit }, index) {
      commit('setcurrentSurveyItemIndex', index)
      router.push({ name: 'Survey' })
    },

    async updateBio({ commit, state }, data) {
      await supabase
        .from('profiles')
        .update({
          bio: data.bio,
          partner_description: data.partner_description,
        })
        .eq('id', state.user.id)
        .select()
    },
  },
  modules: {},
  plugins: [
    (store) => {
      store.subscribe((mutation, state) => {
        // console.log("update state", mutation);
        //localStorage.setItem("store", JSON.stringify(state));
      })
    },
  ],
})
